<template>
    <div id="container2" ref="container">
    </div>
</template>

<script>
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader';

export default {
    data() {
        return {
            mouseX: 0,
            mouseY: 0,
            maxDivisions: 4,
            minDivisions: 4,
            bloomIncrement: 0.1,
        };
    },
    mounted() {
        this.init();
        this.addGlobe();
        this.setupMouseInteraction();
        this.setupPostProcessing();
        this.animate();
    },
    methods: {
        init() {
            this.scene = new THREE.Scene();
            this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
            this.renderer.setClearColor(0x000000, 0); // Set background color to transparent
            this.camera = new THREE.PerspectiveCamera(
                75,
                512 / 512,
                0.1,
                1000
            );
            this.$refs.container.appendChild(this.renderer.domElement);
            this.renderer.setSize(512, 512);

        },
        addGlobe() {
            const distance = 2; // Initial distance from the center
            const maxDistance = Math.sqrt(2); // Maximum distance from the center

            // Calculate the number of subdivisions based on the distance from the center
            const divisions = Math.floor(
                (this.maxDivisions - this.minDivisions) *
                (1 - distance / maxDistance) +
                this.minDivisions
            );

            const geometry = new THREE.IcosahedronGeometry(2, divisions);
            const wireframe = new THREE.WireframeGeometry(geometry);

            this.globe = new THREE.LineSegments(wireframe);
            this.globe.material.depthTest = true;
            this.globe.material.opacity = 1;
            this.globe.material.transparent = true;
            this.globe.rotation.z = THREE.MathUtils.degToRad(-24.3);
            this.scene.add(this.globe);
            this.camera.position.z = 5;
        },
        setupMouseInteraction() {
            document.addEventListener('mousemove', this.onMouseMove);
            document.addEventListener('mouseout', this.onMouseOut);
            document.addEventListener('click', this.onMouseClick);
        },
        setupPostProcessing() {
            this.composer = new EffectComposer(this.renderer);

            const renderPass = new RenderPass(this.scene, this.camera);
            this.composer.addPass(renderPass);

            this.bloomPass = new UnrealBloomPass(
                new THREE.Vector2(window.innerWidth, window.innerHeight),
                1.5,
                0.4,
                0.85
            );
            this.bloomPass.strength = 0.5;
            this.composer.addPass(this.bloomPass);

            this.chromaticAberrationPass = new ShaderPass(RGBShiftShader);
            this.chromaticAberrationPass.uniforms.amount.value = 0.0001;
            this.composer.addPass(this.chromaticAberrationPass);
        },
        onMouseMove(event) {
            const { clientX, clientY } = event;
            const { innerWidth, innerHeight } = window;
            this.mouseX = (clientX / innerWidth) * 2 - 1;
            this.mouseY = (clientY / innerHeight) * 2 - 1;

            const distance = Math.sqrt(this.mouseX ** 2 + this.mouseY ** 2);
            const maxDistance = Math.sqrt(2);
            const maxAmount = 0.01;
            this.chromaticAberrationPass.uniforms.amount.value =
                (distance / maxDistance) * maxAmount;

            this.globe.rotation.z = THREE.MathUtils.degToRad(-24.3);
            this.scene.add(this.globe);
        },
        onMouseOut() {
            const rotationSpeed = 0.01;
            this.globe.rotation.x += this.mouseY * rotationSpeed;
            this.globe.rotation.y += this.mouseX * rotationSpeed;
        },
        onMouseClick() {
            this.bloomPass.strength += this.bloomIncrement;
        },
        rotateGlobe() {
            const rotationSpeed = 0.01;
            this.globe.rotation.x += this.mouseY * rotationSpeed;
            this.globe.rotation.y += this.mouseX * rotationSpeed;
        },
        animate() {
            this.rotateGlobe();
            this.composer.render();
            window.requestAnimationFrame(this.animate);
        },
    },
};
</script>

<style lang="scss">
#container2 {
    opacity:0.5;
    border-radius: 100%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    canvas {
        width: 100%!important;
        height: 100%!important;
    }
}
</style>
