<template>
  <div ref="container"></div>
</template>

<script>
import * as THREE from 'three';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';

export default {
  mounted() {
    this.init();
    this.addDots();
    this.addAdditionalDots();
    this.initPostProcessing();
    this.animate();
  },
  methods: {
    init() {
      this.scene = new THREE.Scene();
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.camera = new THREE.PerspectiveCamera(
        18,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      this.$refs.container.appendChild(this.renderer.domElement);
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.mouse = new THREE.Vector2(); // Store mouse coordinates
      window.addEventListener('mousemove', this.onMouseMove, false);
    },
    addDots() {
      const dotCount = 800; // Number of dots
      const dotMaterial = new THREE.PointsMaterial({ color: 0xffA500, size: 0.0025 });

      const dotGeometry = new THREE.BufferGeometry();
      const positions = new Float32Array(dotCount * 3);
      for (let i = 0; i < dotCount; i++) {
        const theta = Math.random() * Math.PI * 2;
        const phi = Math.random() * Math.PI;

        positions[i * 3] = Math.sin(phi) * Math.cos(theta);
        positions[i * 3 + 1] = Math.sin(phi) * Math.sin(theta);
        positions[i * 3 + 2] = Math.cos(phi);
      }
      dotGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

      this.dots = new THREE.Points(dotGeometry, dotMaterial);
      this.scene.add(this.dots);
    },
    addAdditionalDots() {
      const dotCount = 200; // Number of dots
      const dotMaterial = new THREE.PointsMaterial({ color: 0xffA500, size: 0.0025 });

      const dotGeometry = new THREE.BufferGeometry();
      const positions = new Float32Array(dotCount * 3);
      for (let i = 0; i < dotCount; i++) {
        const theta = Math.random() * Math.PI * 2;
        const phi = Math.random() * Math.PI;

        // Generate random radius for the additional dots
        const radius = 5 + Math.random() * 2;

        positions[i * 3] = Math.sin(phi) * Math.cos(theta) * radius;
        positions[i * 3 + 1] = Math.sin(phi) * Math.sin(theta) * radius;
        positions[i * 3 + 2] = Math.cos(phi) * radius;
      }
      dotGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

      this.additionalDots = new THREE.Points(dotGeometry, dotMaterial);
      this.scene.add(this.additionalDots);
    },
    initPostProcessing() {
      this.composer = new EffectComposer(this.renderer);
      const renderPass = new RenderPass(this.scene, this.camera);
      this.composer.addPass(renderPass);

      const bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        1.5, // strength
        0.4, // radius
        0.85 // threshold
      );
      this.composer.addPass(bloomPass);
    },
    animate() {
      window.requestAnimationFrame(this.animate);
      this.rotateDots();
      this.composer.render();
    },
    rotateDots() {
      // Calculate rotation angles based on mouse position
      const rotationX = this.mouse.y * 0.1;
      const rotationY = this.mouse.x * 0.1;

      this.dots.rotation.x += (rotationX - this.dots.rotation.x) * 0.1;
      this.dots.rotation.y += (rotationY - this.dots.rotation.y) * 0.1;
      this.additionalDots.rotation.x += (rotationX - this.additionalDots.rotation.x) * 0.05;
      this.additionalDots.rotation.y += (rotationY - this.additionalDots.rotation.y) * 0.05;
      
      // Auto-rotate stars
      const autoRotationX = Date.now() * 0.00005;
      const autoRotationY = Date.now() * 0.00005;

      this.dots.rotation.x += autoRotationX;
      this.dots.rotation.y += autoRotationY;
      this.additionalDots.rotation.x += autoRotationX * 0.5;
      this.additionalDots.rotation.y += autoRotationY * 0.5;
    },
    onMouseMove(event) {
      // Update mouse coordinates
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    },
  },
};
</script>

<style>
#container {
  width: 100%;
  height: 100%;
}
</style>
