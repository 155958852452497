<template>
    <div id="dotcont" ref="container"></div>
  </template>
  
  <script>
  import * as THREE from 'three';
  import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
  import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
  import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
  
  export default {
    mounted() {
      this.init();
      this.addGlobe();
      this.initPostProcessing();
      this.animate();
    },
    methods: {
      init() {
        this.scene = new THREE.Scene();
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this.camera = new THREE.PerspectiveCamera(
          75,
          window.innerWidth / window.innerHeight,
          0.1,
          1000
        );
        this.$refs.container.appendChild(this.renderer.domElement);
        this.renderer.setSize(598, 598);
        this.renderer.setClearColor(0x000000, 0); // Set background color to transparent
      },
      addGlobe() {
        const geometry = new THREE.IcosahedronGeometry(2, 10, 10);
        const wireframe = new THREE.WireframeGeometry(geometry);
  
        this.globe = new THREE.LineSegments(wireframe);
        this.globe.material.depthTest = true;
        this.globe.material.opacity = 0.25;
        this.globe.material.transparent = true;
        this.globe.rotation.z = THREE.MathUtils.degToRad(-24.3);
        this.camera.position.z = 5;
  
        this.dots = [];
        this.dotIndex = 0;
        this.dotCount = wireframe.attributes.position.count;
        this.showNextDot();
      },
      showNextDot() {
        if (this.dotIndex < this.dotCount) {
          const dotGeometry = new THREE.BufferGeometry();
          const dotMaterial = new THREE.PointsMaterial({ color: 0xffffff, size: 0.055 });
  
          const positions = new Float32Array(3);
          positions[0] = this.globe.geometry.attributes.position.getX(this.dotIndex);
          positions[1] = this.globe.geometry.attributes.position.getY(this.dotIndex);
          positions[2] = this.globe.geometry.attributes.position.getZ(this.dotIndex);
  
          dotGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
  
          const dot = new THREE.Points(dotGeometry, dotMaterial);
          this.scene.add(dot);
          this.dots.push(dot);
  
          this.dotIndex++;
  
          // Rotate the dot
          const rotationSpeed = Math.random() * 0.02 + 0.01;
          this.rotateDot(dot, rotationSpeed);
  
          if (this.dots.length > 50) {
            const oldestDot = this.dots.shift();
            this.scene.remove(oldestDot);
          }
  
          setTimeout(this.showNextDot, Math.random() * 1000);
        }
      },
      rotateDot(dot, rotationSpeed) {
        dot.rotation.x += rotationSpeed;
        dot.rotation.y += rotationSpeed;
        dot.rotation.z += rotationSpeed;
      },
      initPostProcessing() {
        this.composer = new EffectComposer(this.renderer);
        const renderPass = new RenderPass(this.scene, this.camera);
        this.composer.addPass(renderPass);
        const bloomPass = new UnrealBloomPass(
          new THREE.Vector2(window.innerWidth, window.innerHeight),
          1.5,
          0.4,
          0.85
        );
        this.composer.addPass(bloomPass);
      },
      animate() {
        window.requestAnimationFrame(this.animate);
        this.rotateDots();
        this.globe.rotateOnAxis(new THREE.Vector3(0, 1, 0), 0.01);
        this.composer.render();
      },
      rotateDots() {
        for (const dot of this.dots) {
          dot.rotation.x += 0.01;
          dot.rotation.y += 0.01;
          dot.rotation.z += 0.01;
        }
      },
    },
  };
  </script>
  
  <style>
  </style>
  