<template>
    <div>
        <DashedCircleProgress />
        <Globe />
        <GlobeDots />
        <Arcs />
        <Stars />
        <Legs />
    </div>
</template>
  
<script>
import Arcs from '@/components/Arcs.vue';
import Legs from '@/components/Legs.vue';
import Stars from '@/components/Stars.vue';
import Globe from '@/components/Globe.vue';
import GlobeDots from '@/components/GlobeDots.vue';
import DashedCircleProgress from '@/components/DashedCircleProgress.vue';
export default {
    components: {
        Globe,
        Legs,
        DashedCircleProgress,
        GlobeDots,
        Arcs,
        Stars
    },
};
</script>

<style>

</style>