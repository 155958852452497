<template>
    <div ref="container" class="threejs-container"></div>
  </template>
  
  <script>
  import * as THREE from 'three';
  
  export default {
    mounted() {
      this.init();
      this.startAnimation();
      window.addEventListener('mousemove', this.handleMouseMove);
    },
    beforeUnmount() {
      window.removeEventListener('mousemove', this.handleMouseMove);
    },
    data() {
      return {
        mouseX: 0,
        mouseY: 0,
      };
    },
    methods: {
      init() {
        // Create a scene
        const scene = new THREE.Scene();
  
        // Create a camera
        const camera = new THREE.PerspectiveCamera(
          75,
          window.innerWidth / window.innerHeight,
          0.1,
          1000
        );
        camera.position.set(0, 0, 5);
        camera.lookAt(0, 0, 0);
  
        // Create a renderer
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        this.$refs.container.appendChild(renderer.domElement);
  
        // Create a circle geometry
        const circleRadius = 1;
        const circleSegments = 64;
        const circleGeometry = new THREE.CircleGeometry(circleRadius, circleSegments);
        const circleMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, wireframe: true });
        const circleMesh = new THREE.Mesh(circleGeometry, circleMaterial);
        scene.add(circleMesh);
  
        // Create radial spokes
        const spokeCount = 8;
        const spokeGeometry = new THREE.BufferGeometry();
        const spokeMaterial = new THREE.LineBasicMaterial({ color: 0xffffff });
        const spokePositions = [];
        const angleStep = (2 * Math.PI) / spokeCount;
  
        for (let i = 0; i < spokeCount; i++) {
          const angle = i * angleStep;
          const x = circleRadius * Math.cos(angle);
          const y = circleRadius * Math.sin(angle);
          spokePositions.push(new THREE.Vector3(0, 0, 0), new THREE.Vector3(x, y, 0));
        }
  
        spokeGeometry.setFromPoints(spokePositions);
        const spokeMesh = new THREE.LineSegments(spokeGeometry, spokeMaterial);
        scene.add(spokeMesh);
  
        // Create a wireframe sphere geometry
        const sphereGeometry = new THREE.SphereGeometry(circleRadius, circleSegments, circleSegments);
        const wireframeMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, wireframe: true, opacity: 0, transparent: true });
        const wireframeMesh = new THREE.Mesh(sphereGeometry, wireframeMaterial);
        wireframeMesh.rotation.x = Math.PI / 2;
        wireframeMesh.visible = false;
        scene.add(wireframeMesh);
  
        // Animation variables
        let isAnimating = false;
        let animationProgress = 0;
  
        // Store the scene, camera, renderer, spokeMesh, and wireframeMesh as component data
        this.scene = scene;
        this.camera = camera;
        this.renderer = renderer;
        this.spokeMesh = spokeMesh;
        this.wireframeMesh = wireframeMesh;
        this.isAnimating = isAnimating;
        this.animationProgress = animationProgress;
      },
      startAnimation() {
        if (!this.isAnimating) {
          this.isAnimating = true;
          this.wireframeMesh.visible = true;
          this.spokeMesh.visible = false;
          this.animate();
        }
      },
      animate() {
        if (this.isAnimating) {
          this.animationProgress += 0.01;
  
          if (this.animationProgress >= 1) {
            // Animation completed
            this.animationProgress = 1;
            this.wireframeMesh.rotation.y += Math.PI / 2;
          }
  
          // Lerp opacity from 0 to 1
          this.wireframeMesh.material.opacity = this.animationProgress;
  
          // Lerp scale from circle to sphere
          const scale = THREE.MathUtils.lerp(1, this.wireframeMesh.geometry.parameters.radius, this.animationProgress);
          this.wireframeMesh.scale.set(scale, scale, scale);
  
          // Calculate rotation speed based on mouse position
          const speed = this.calculateRotationSpeed();
  
          // Rotate the sphere
          this.wireframeMesh.rotation.y += speed;
  
          this.renderer.render(this.scene, this.camera);
        }
  
        requestAnimationFrame(this.animate);
      },
      handleMouseMove(event) {
        const containerRect = this.$refs.container.getBoundingClientRect();
        this.mouseX = event.clientX - containerRect.left - containerRect.width / 2;
        this.mouseY = event.clientY - containerRect.top - containerRect.height / 2;
      },
      calculateRotationSpeed() {
        const maxSpeed = 0.01;
        const maxDistance = Math.sqrt(
          Math.pow(window.innerWidth / 2, 2) + Math.pow(window.innerHeight / 2, 2)
        );
        const distanceToCenter = Math.sqrt(
          Math.pow(this.mouseX, 2) + Math.pow(this.mouseY, 2)
        );
        const speed = (distanceToCenter / maxDistance) * maxSpeed;
  
        return speed;
      },
    },
  };
  </script>
  
  <style>
  .threejs-container {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
  }
  </style>
  