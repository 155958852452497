<template>
    <div class="topological-map" ref="container"></div>
  </template>
  
  <script>
  import * as THREE from 'three';
  
  export default {
    mounted() {
      this.init();
      this.createScene();
      this.createTopologicalMap();
      this.animate();
    },
    methods: {
      init() {
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        this.camera.position.set(0, 0, 2);
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.$refs.container.appendChild(this.renderer.domElement);
      },
      createScene() {
        const light = new THREE.DirectionalLight(0xffffff);
        light.position.set(1, 1, 1).normalize();
        this.scene.add(light);
      },
      createTopologicalMap() {
        const geometry = new THREE.IcosahedronGeometry(0.6, 6);
        const material = new THREE.MeshBasicMaterial({
          color: 0x44aaff,
          wireframe: true,
        });
        const topologicalMap = new THREE.Mesh(geometry, material);
        this.scene.add(topologicalMap);
      },
      animate() {
        requestAnimationFrame(this.animate);
        this.updateTopologicalMap();
        this.renderer.render(this.scene, this.camera);
      },
      updateTopologicalMap() {
        this.scene.children[0].rotation.x += 0.01;
        this.scene.children[0].rotation.y += 0.01;
      },
    },
  };
  </script>
  
  <style>
  .topological-map {
    width: 100%;
    height: 100%;
  }
  </style>
  